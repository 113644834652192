import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import ProjectCard from "../components/project_card"

import EmailCapture from "../components/email_capture"
import FeedbackCard from "../components/feedback_card"
import AboutUs from "../components/about_us"
import ArticlesList from "../components/articles_list"

import ZeroBackground from "../svg/zero_background"

const SiteIndex = ({ data, location }) => {
  const posts = data.allMarkdownRemark.nodes

  return (
    <Layout location={location} title="Zero hype. Zero fluff.">
      <section className="flex flex-wrap-reverse hero w-full">
        <div className="flex-auto xl:ml-20 lg:w-1/2 p-4 lg:p-12 max-w-4xl z-10">
          <h1 className="my-2 jumbo">
            Zero Fluff. <span className="break">Zero Hype.</span>
          </h1>
          <h2>Taking Web3 Products Zero to One.</h2>
          <p className="lead leading-tight">
            Team Zero is a ConsenSys Mesh innovation team dedicated to rapidly
            building products with Web3 technologies. We test them in real-world scenarios
            to gauge their desirability, feasibility and viability
            in specific markets. Our interests span media, social networks, sports,
            memorabilia, ticketing, digital fabrication and civic engagement.
          </p>
          <p className="my-2">
            Are you curious how blockchain and Web3 technologies might transform
            your industry? Fill out our contact form below and we will reach out!
          </p>
        </div>
        <div className="absolute lg:relative mix-blend-overlay lg:mix-blend-normal	 top-20 right-0 lg:ml-4 lg:w-1/3 lg:mt-0">
          <ZeroBackground />
        </div>
      </section>
      <section className="projects">
        <h2 className="m-6">Team Projects</h2>
        <div className="m-6">
          <div className="flex flex-wrap">
            {/* =================== current projects ====================== */}
            <ProjectCard
              title="Datapult"
              descr="A web3 based storage app for iOS"
              status="current"
            >
              <StaticImage
                className="w-full h-40 rounded-t-lg"
                src="../images/datapult_thumbnail.jpg"
                alt="Datapult project picture"
              />
            </ProjectCard>

            <ProjectCard
              title="NFTix"
              descr="Advanced digital fan experience based on Ethereum"
              status="current"
            >
              <StaticImage
                className="w-full h-40 rounded-t-lg"
                src="../images/nftix_thumbnail.jpg"
                alt="NFTix project picture"
              />
            </ProjectCard>

            {/* =================== future projects ====================== */}

            <ProjectCard
              title="GeoPass"
              descr="A privacy preserving Ethereum based geo-spatial side-chain that enables location based tokens"
              status="future"
            >
              <StaticImage
                className="w-full h-40 rounded-t-lg"
                src="../images/geopass_thumbnail.jpg"
                alt="GeoPass project picture"
              />
            </ProjectCard>

            <ProjectCard
              title="DocuNet"
              descr="A platform for building and exchanging business documents with independently verifiable integrity."
              status="future"
            >
              <StaticImage
                className="w-full h-40 rounded-t-lg"
                src="../images/duradox_thumbnail.jpg"
                alt="DocuNet project picture"
              />
            </ProjectCard>

            <ProjectCard
              title="PriNFT.cloud"
              descr="Pay-to-print model based on NFT royalties applied to the 3D printing space."
              status="future"
              slug="prinftcloud"
            >
              <StaticImage
                className="w-full h-40 rounded-t-lg"
                src="../images/prinftcloud_thumbnail.jpg"
                alt="PriNFT cloud project picture"
              />
            </ProjectCard>

            {/* =================== past projects ====================== */}

            <ProjectCard
              title="Rosen NFT Drop"
              descr="Word based artist Jonathan Rosen chance based minting of word-art NFTs"
              status="past"
              slug="rosen"
            >
              <StaticImage
                className="w-full h-40 rounded-t-lg"
                src="../images/rosen_thumbnail.png"
                alt="Jonathan Rosen art NFT project picture"
              />
            </ProjectCard>

            <ProjectCard
              title="Citizen Cope Drop"
              descr="First ever NFT concert ticket for a Citizen Cope concert in NYC. NFT holder also gets exclusive content access."
              status="past"
              slug="cope"
            >
              <StaticImage
                className="w-full h-40 rounded-t-lg"
                src="../images/cope_thumbnail.png"
                alt="Citizen Cope concert NFT project picture"
              />
            </ProjectCard>

            <ProjectCard
              title="Bootleg"
              descr="Concert footage as an unlock for the NFT holder. Unique shared royalty model for previous NFT owners."
              status="past"
              slug="bootleg"
            >
              <StaticImage
                className="w-full h-40 rounded-t-lg"
                src="../images/bootleg_thumbnail.png"
                alt="Bootleg music concert video NFT project picture"
              />
            </ProjectCard>
          </div>
        </div>
      </section>
      <section>
        <h2 className="m-6">Recent Updates</h2>
        <ArticlesList posts={posts} />
        <div className="float-right mr-8">
          <Link to="/articles">More updates ...</Link>
        </div>
      </section>
      <section className="about m-2 mb-10">
        <h2 className="ml-4 mt-16 mb-4">Get In Touch</h2>
        <div className="lg:flex">
          <div className="max-w-2xl">
            <AboutUs />
          </div>
          <div className="flex-wrap p-6 lg:pt-0">
            <FeedbackCard location="index" />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default SiteIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 4
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          author
          tags
          project
        }
      }
    }
  }
`
