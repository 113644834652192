import * as React from "react"
import { Link } from "gatsby"

interface pCardProps {
  title: string
  descr: string
  status: string
  slug?: string
  children: any
}

const ProjectCard = ({ title, descr, status, slug, children }: pCardProps) => {
  const projectStatus = {
    current: {
      label: "Current Project",
      color: "bg-ptGreen",
    },
    future: {
      label: "Future Project",
      color: "bg-ptYellow",
    },
    past: {
      label: "Previous Project",
      color: "bg-ptBlue",
    },
  }[status]

  const styleStr = `absolute inset-px ${projectStatus.color} shadow-lg transform -translate-x-6 rounded-xl`
  const slugStr = slug
    ? `/projects/${slug}`
    : `/projects/${title.toLowerCase()}`
  const altImageStr = `Project ${title} picture`

  return (
    <div className="relative mx-auto sm:mx-8 my-4 px-3">
      <div className={styleStr}>
        <div className="absolute top-36 -left-24 font-light text-center h-8 w-56 font-bold transform -rotate-90 pt-1">
          {projectStatus.label}
        </div>
      </div>
      <div className="relative w-56 h-80 bg-white shadow-lg border rounded-lg hover:shadow-xl hover:scale-105 transition-all transform duration-200 cursor-pointer">
        <Link to={slugStr}>
          {children}
          <div className="m-4">
            <h1 className="text-xl font-bold text-gray-700">{title}</h1>
            <p className="text-sm mt-2 text-gray-700">{descr}</p>
          </div>
        </Link>
      </div>
    </div>
  )
}

export default ProjectCard
